<template>
  <el-container style="width: 100%;height: 100%;">
    <el-main :style="'width: 100%;height: 100%;min-width: '+$store.state.jm_min_width+'px;min-height: '+$store.state.jm_min_height+'px;padding: 0;margin: 0;'">
      <el-config-provider :locale="zh_cn">
        <router-view v-slot="{ Component, route }">
          <keep-alive v-if="Component">
            <component :is="Component" v-if="route.meta.cacheable"></component>
          </keep-alive>
          <component :is="Component" v-if="!route.meta.cacheable"></component>
        </router-view>
      </el-config-provider>
    </el-main>
  </el-container>
</template>

<script>
  import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
  import en from 'element-plus/dist/locale/en.mjs'
  export default {
    name: 'App',
    data() {
      return {
        zh_cn: zhCn,
        en: en,
        Component: true
      }
    },
    mounted() {
      
    }
  }
</script>

<style>
.detailbox .flexbox:last-child .contentbox_v2{
  border-bottom: none;
}
.detailbox .flexbox:last-child .shrinkbox_v2{
  border-bottom: none;
}
.printflexbox {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.printflexitembox {
  flex:1;
  flex-shrink: 0;
  height: 30px;
  border-right: 1px solid #0F0F0F;
  border-bottom: 1px solid #0F0F0F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: '黑体';
}
.printgrowbox {
  flex-grow:1;
  height: 30px;
  border-right: 1px solid #0F0F0F;
  border-bottom: 1px solid #0F0F0F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: '黑体';
}
.printshrinkbox {
  flex-shrink:0;
  height: 30px;
  border-right: 1px solid #0F0F0F;
  border-bottom: 1px solid #0F0F0F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: '黑体';
}
.heightunset {
  height: unset;
}
.diyselect {
  width: 100%;
}
.flexbox {
  display: flex;
  width: 100%;
  overflow: hidden;
  font-weight: bold;
}
.flexbox .contentbox_v2:last-child {
  border-right: none;
}
.growbox {
  flex-grow:1;
  border: 1px solid #EBEEF5;
  border-left:none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shrinkbox {
  padding: 0 10px 0 10px;
  width: 80px;
  flex-shrink:0;
  min-height:31px;
  border: 1px solid #EBEEF5;
  background-color: #F5F7FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: '黑体';
  text-align: right;
}
.shrinkbox_v2 {
  padding: 0 10px 0 10px;
  width: 80px;
  flex-shrink:0;
  min-height:31px;
  border-bottom: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  background-color: #F5F7FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* font-family: '黑体'; */
  text-align: right;
  font-size: 12px;
}
.contentbox {
  overflow: auto;
  flex:1;
  border: 1px solid #EBEEF5;
  border-left:none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-family: '宋体';
  font-weight: normal;
}
.contentbox_v2 {
  overflow: auto;
  flex:1;
  border-bottom: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-family: '宋体';
  font-weight: normal;
}
.contentbox_border {
  /* border-top: 1px solid #EBEEF5;
  border-bottom: 1px solid #EBEEF5; */
  /* border: 1px solid #EBEEF5; */
}
.contentbox_border::-webkit-scrollbar {
  width: 4px;
}
.contentbox_border::-webkit-scrollbar-thumb {
  background-color: rgba(139, 139, 139, 0.3);
  border-radius: 10px;
  width: 4px;
}
.notop {
  border-top: none;
}
.nobottom {
  border-bottom: none;
}
.noleft {
  border-left: none;
}
.noright {
  border-right: none;
}
.hidetop {
  border-top: 1px solid #fff;
}
.hidebottom {
  border-bottom: 1px solid #fff;
}
.hideleft {
  border-left: 1px solid #fff;
}
.hideright {
  border-right: 1px solid #fff;
}
.border_top {
  border-top: 1px solid #EBEEF5;
}
.border_bottom {
  border-bottom: 1px solid #EBEEF5;
}
.border_left {
  border-left: 1px solid #EBEEF5;
}
.border_right {
  border-right: 1px solid #EBEEF5;
}
.noborder {
  border: none;
}
.nobgc {
  background-color: unset;
}
.border {
  border: 1px solid #EBEEF5;
}
.searchBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
}
.div_input {
  padding: 0 10px 0 10px;
  /*  */
}
.readonlycolor {
  background-color: #FAFAFA;
}

/* .el-table__row:hover > td {
    background-color: inherit !important;
}

.el-table__row--striped:hover > td {
    background-color: inherit !important;
} */
/* background-color: unset !important; */
.diytablebox .el-table__row:hover > td {
    background-color: unset !important;
}
.diytablebox .el-table__row--striped:hover > td {
    background-color: unset !important;
}
.clickitem {
  padding: 3px 10px;
  display: flex;
}
.itemleft {
  flex-grow: 1;
  color: #000;
}
.itemright {
  flex-shrink: 0;
  font-size: 13px;
  color: #000;
}
.clickitem:hover {
  background-color: #efefef;
}
.searchBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  flex: 1;
  flex-shrink: 0;
}
.searchContent {
  width: 100px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  font-size:14px;
  color:#a0a0a0;
}
</style>
