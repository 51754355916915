import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import * as icons from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/display.css'

// import Jm_Info_Aside from '@/components/Jm_Info_Aside/JmInfoAside.vue'
import Jm_ModalView from '@/components/Jm_ModalView/JmModalView.vue'
// import Jm_PrintModal from '@/components/Jm_PrintModal/JmPrint.vue'

// npm install --save vue-cookies
// npm install axios
// npm install element-plus --save
import jm_util from './assets/utils/base'
import jm_filters from './assets/utils/filters'
// import print from 'vue3-print-nb'

const app = createApp(App)

Object.keys(icons).forEach((key) => {
  app.component(key, icons[key])
})

app.component('jm-modal-view', Jm_ModalView)

app.provide('$utils', jm_util)
app.provide('$filters', jm_filters)

app.use(store).use(router).use(ElementUI)

app.mount('#app')
