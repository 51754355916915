import { createStore } from 'vuex'

export default createStore({
  state: {
    isOldMenu: true,
    isOldRouter: true,
    // Tab页切换是否刷新数据
    routerMode_refrash: false,
    menu_active: '/admin/modal',
    // Tab页数据menuTabs
    menuTabs: [],
    menuTabs_active: '',
    tab_panel_height: 0,
    // 右键弹出菜单
    pop_left: 0,
    pop_top: 0,
    pop_width: 200,
    pop_height: 300,
    pop_show: false,
    // =====================================================
    jm_min_width: 1440,
    jm_min_height: 500,
    separator: '/',
    body_height: 0,
    // 弹出窗内部高度
    dialog_body_height: 0,
    // 关闭弹窗前是否有提示
    close_showtip: true,
    
    tableRowStyle: function (table, obj) {
      var rowstyle = ''
      if (table.rowIndex % 2 != 0) {
        rowstyle = 'background-color: #FAFAFA;'
      }
      if (!obj.id && obj.id != 0) {
        
      } else {
        if (table.row.id == obj.id) {
          if (!obj.color) {
            rowstyle = 'background-color: #00D6E1;'
            // rowstyle = 'background-color: #ECF5FF;'
          } else {
            rowstyle = 'background-color: ' + obj.color + ';'
            // #00D6E1
          }
        }
      }
      if (!!obj.field) {
        if (table.row[obj.field]) {
          rowstyle = 'background-color: #ccc;'
        }
      }
      return rowstyle
    },
    
    outheaderstyle: {
      'background-color': '#F5F7FA',
      'font-weight': 'bold',
      'color': 'rgb(96, 98, 102)'
    },
  },
  getters: {
  },
  mutations: {
    set_body_height: function (state, data) {
      state.body_height = data
      state.dialog_body_height = data - 60 - 54 - 62 - 8
      state.tab_panel_height = data - 60 - 41 - 10
    },
    set_pop_attr: function (state, obj) {
      var keys = Object.keys(obj)
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] == 'get_event') {
          continue
        }
        state[keys[i]] = obj[keys[i]]
      }
      if (!!obj.get_event) {
        if (state.isOldMenu) {
          if (state.isOldRouter) {
            state.pop_left = obj.get_event.clientX + window.pageXOffset + 2
            state.pop_top = obj.get_event.clientY
          } else {
            state.pop_left = obj.get_event.clientX - 200 + window.pageXOffset
            state.pop_top = obj.get_event.clientY - 100
          }
        } else {
          if (state.isOldRouter) {
            state.pop_left = obj.get_event.clientX + window.pageXOffset + 2
            state.pop_top = obj.get_event.clientY
          } else {
            state.pop_left = obj.get_event.clientX - 138 + window.pageXOffset
            state.pop_top = obj.get_event.clientY - 100
          }
        }
      }
    },
    set_menuTabs: function (state, obj) {
      state.menuTabs_active = obj.name
      if (state.menuTabs.findIndex((item => obj.name == item.name)) < 0) {
        state.menuTabs.push(obj)
        state.routerMode_refrash = true
      } else {
        state.routerMode_refrash = false
      }
    },
    remove_menuTabs: function (state, name) {
      state.routerMode_refrash = false
      const del_index = state.menuTabs.findIndex((item => name == item.name))
      state.menuTabs.splice(del_index, 1)
      if (state.menuTabs.length != 0) {
        state.menuTabs_active = state.menuTabs[(state.menuTabs.length - 1)].name
        state.menu_active = state.menuTabs[(state.menuTabs.length - 1)].path
      } else {
        state.menuTabs_active = ''
      }
    },
    set_menuTabs_active: function (state, val) {
      state.routerMode_refrash = false
      state.menuTabs_active = val
      state.menu_active = state.menuTabs.find((item => val == item.name)).path
    }
  },
  actions: {
  },
  modules: {
  }
})
