const filters = {
    DateFilter: function (date, fmt) {
        date = new Date(date)
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        }
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                let str = o[k] + ''
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
            }
        }
        return fmt
    },
    DateChange: function (date) {
        date = parseFloat(date)
        var year, month, day, hour, minute, second = ""
        hour = Math.floor((date / 60))
        console.log(hour)
        if (date <= 60) {
            return date + '分钟'
        } else {
            return hour + '小时' + ((date % 60)==0?'':((date % 60) + '分钟'))
        }
        
        // if (hour <= 24) {
        //     // 不到一天
        //     return hour + '小时' + ((date % 60)==0?'':((date % 60) + '分钟'))
        // } else {
        //     day = Math.floor((date / 60 / 24))
        //     if (day <= 30) {
        //         // 不到一个月
        //         return day + '天' 
        //             + ((date % (60 * 24))==0?'':((date % (60 * 24)) + '小时')) 
        //             + ((date % 60)==0?'':((date % 60) + '分钟'))
        //     } else {
        //         month = Math.floor((date / 60 / 24 / 30))
        //         if (month <= 12) {
        //             // 不到一年
        //             return month + '个月' 
        //                 + ((date % (60 * 24 * 30))==0?'':((date % (60 * 24 * 30)) + '天')) 
        //                 + ((date % (60 * 24))==0?'':((date % (60 * 24)) + '小时')) 
        //                 + ((date % 60)==0?'':((date % 60) + '分钟'))
        //         } else {
        //             year = Math.floor((date / 60 / 24 / 30 / 12))
        //             return year + '年'
        //                 + ((date % (60 * 24 * 30 * 12))==0?'':((date % (60 * 24 * 30 * 12)) + '天')) 
        //                 + ((date % (60 * 24 * 12))==0?'':((date % (60 * 24 * 12)) + '天')) 
        //                 + ((date % (60 * 24))==0?'':((date % (60 * 24)) + '小时')) 
        //                 + ((date % 60)==0?'':((date % 60) + '分钟'))
        //         }

        //     }
        // }
    }
}
export default filters
