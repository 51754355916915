<template>
    <div class="textcontent">
        <div> 404Error</div>
        <div>未找到相应页面</div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.textcontent {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    color: red;
    font-size: 30px;
}
</style>