import { createRouter, createWebHistory } from 'vue-router'
import cookies from 'vue-cookies'
import store from 'vuex'
import { defineAsyncComponent, onMounted } from 'vue'

import jm_util from '../assets/utils/base'
import { setTimeout } from 'core-js'

import NotFound from '@/views/Error.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      requireAuth: true
    },
    component: () => import('@/views/admin/frame.vue'),
    children: [
      // 模板页路径
      {
        path: '/admin/modal',
        name: 'admin_modal',
        meta: {
          isMenu: true,
          title: '模板页',
          componentUrl: defineAsyncComponent(() => {
            return import('@/views/admin/z_Modal/index.vue')
          })
        },
        component: () => import('@/views/admin/z_Modal/index.vue')
      },
      // 用户列表路径
      {
        path: '/admin/user',
        name: 'admin_user',
        meta: {
          isMenu: true,
          title: '用户列表',
          componentUrl: defineAsyncComponent(() => {
            return import('@/views/admin/a_user/index.vue')
          })
        },
        component: () => import('@/views/admin/a_user/index.vue')
      },
      // 设备列表路径
      {
        path: '/admin/device',
        name: 'admin_device',
        meta: {
          isMenu: true,
          title: '设备列表',
          componentUrl: defineAsyncComponent(() => {
            return import('@/views/admin/b_device/index.vue')
          })
        },
        component: () => import('@/views/admin/b_device/index.vue')
      },
      // 收益列表路径
      {
        path: '/admin/profit',
        name: 'admin_profit',
        meta: {
          isMenu: true,
          title: '收益列表',
          componentUrl: defineAsyncComponent(() => {
            return import('@/views/admin/c_profit/index.vue')
          })
        },
        component: () => import('@/views/admin/c_profit/index.vue')
      },
      // 收益列表路径
      {
        path: '/admin/cash',
        name: 'admin_cash',
        meta: {
          isMenu: true,
          title: '提现审核',
          componentUrl: defineAsyncComponent(() => {
            return import('@/views/admin/d_moneyStat/index.vue')
          })
        },
        component: () => import('@/views/admin/d_moneyStat/index.vue')
      },
      


      // 模板页路径
      {
        path: '/admin/modal2_0',
        name: 'modal2_0',
        meta: {
          isMenu: true,
          title: '测试2',
          componentUrl: defineAsyncComponent(() => {
            return import('@/views/admin/z_Modal2_0/index.vue')
          })
        },
        component: () => import('@/views/admin/z_Modal2_0/index.vue')
      }

    ]
  },






  // 404 路由，放在最后
  { path: '/:pathMatch(.*)', component: NotFound }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// // const routes = [
// //   {
// //     path: '/',
// //     name: 'login',
// //     component: () => import('@/views/Login.vue')
// //   }
// // ]


// let menu = []
// function getRouter () {
//   let that = this
//   const data = [
//     {
//       path: '/',
//       name: 'login',
//       componentUrl: '/views/Login.vue',
//       children: []
//     },
//     {
//       path: '/admin',
//       name: 'admin',
//       meta: {
//         requireAuth: true
//       },
//       componentUrl: '/views/admin/frame.vue',
//       children: [
//         // 模板页路径
//         {
//           path: '/admin/modal',
//           name: 'admin_modal',
//           meta: {
//             isMenu: true,
//             title: '模板页'
//           },
//           componentUrl: '/views/admin/z_Modal/index.vue',
//           children: []
//         },
//         // 订单管理
//         {
//           path: '/admin/order',
//           name: 'order',
//           meta: {
//             isMenu: true,
//             title: '订单管理'
//           },
//           componentUrl: '/views/admin/order/index.vue',
//           children: []
//         },
//         // 模板页路径
//         {
//           path: '/admin/test2',
//           name: 'test2',
//           meta: {
//             isMenu: true,
//             title: '测试2'
//           },
//           componentUrl: '/views/admin/test2/index.vue',
//           children: []
//         }
//       ]
//     }
//   ]
//   return new Promise((resolve, reject) => {
//     // const loading = 
//     setTimeout(() => {
//       resolve(data)
//     }, 2000)
//     // jm_util.post({
//     //   url: '',
//     //   params: login,
//     //   ok: function (res) {
//     //     resolve(res)
//     //   }
//     // })
//   })
// }

// // 动态生成路由
// const generateRoutes = function(item) {
//   const routerList = [];
//   item.forEach(route => {
//     const r = {
//       path: route.path,
//       name: route.name,
//       // component: () => import(route.componentUrl),
//       component: () => import(`@${route.componentUrl}`),
//       meta: { 
//         ...route.meta,
//         componentUrl: defineAsyncComponent(() => {
//           return import(`@${route.componentUrl}`)
//         })
//       },
//       children: route.children && generateRoutes(route.children),
//     }
 
//     routerList.push(r)
//   })
//   return routerList
// }

// async function setRouter () {
//   menu = await getRouter()
//   const routeRes = generateRoutes(menu)
//   // console.log(routeRes)
//   // router.addRoute(
//   //   {
//   //     path: '/',
//   //     name: 'login',
//   //     component: () => import('@/views/Login.vue')
//   //   }
//   // )
//   routeRes.forEach(route => {
//     console.log(route)
//     router.addRoute(route)
//   })
// }
// setRouter()



// let registerRouteFresh = true; //防止多次调用添加路由
router.beforeEach((to, from, next) => {
  let strpath = to.fullPath
  // console.log(to)
  if (to.meta.requireAuth) {
    // 是否登录 cookies.get('token')
    // if (true) {
      
    if (cookies.get('token')) {
      // if (registerRouteFresh) {
      //   generateRoutes(menu).forEach(item => {
      //     router.addRoute(item)
      //   })
      //   registerRouteFresh = false
      // }
      if (to.meta.isMenu) {
        store.useStore().commit('set_menuTabs', {
          name: to.name,
          path: to.path,
          title: to.meta.title?to.meta.title:'',
          componentUrl: to.meta.componentUrl?to.meta.componentUrl:'',
        })
        // console.log(store.useStore().state.menuTag)
      }
      next()
    } else {
      next({
        path: '/',
        query: { redirect: strpath }
      })
    }
  } else {
    // console.log('没有验证')
    next()
  }
})

export default router
