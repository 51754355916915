<template>
  <div @click.right.prevent="" @click.stop="" v-show="$store.state.pop_show" class="popbox" :style="'max-height:'+ $store.state.pop_height +'px;width:'
  +$store.state.pop_width+'px;left:' + $store.state.pop_left + 'px;top:'+$store.state.pop_top+'px;'">
    <slot name="pop_body"></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.popbox {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    background-color:#fff;
    box-shadow: 1px 2px 6px rgba(0,0,0,0.4);
    border-radius: 6px;
    /* justify-content: center; */
}
</style>