const language = {
  // #region ================== 中文 =================
  "cn": {
    // 弹窗
    "dialog_close": "关 闭",
    "dialog_save": "保 存",
    "dialog_check": "确 定",
    "dialog_cancel": "取 消",
    "confirm_tip": "提 示",
    "aside_menu_title": "订单号",
    "table_operation": "操作",
    "system_lang_change": "Changing language······",
    // 单位
    "unit_weight": "重量",
    "unit_yardage": "码数",
    "unit_inch": "英寸数",
    "unit_meter": "米数",

    "unit_1": "公斤",
    "unit_2": "米",
    "unit_3": "码",
    "unit_4": "英寸",
    // 自动补充提示
    "unit_auto": [{
      value: '只'
    }, {
      value: '条'
    }, {
      value: '个'
    }, {
      value: '米'
    }, {
      value: '分米'
    }, {
      value: '厘米'
    }, {
      value: '毫米'
    }, {
      value: '码'
    }, {
      value: '寸'
    }, {
      value: '公斤'
    }, {
      value: '斤'
    }, {
      value: '千克'
    }, {
      value: '克'
    }],
    // 基础
    "base_tip1": "是否执行该操作",
    "base_tip2": "处理中……",
    "base_tip3": "请联系管理员",
    "base_tip4": "网络连接错误，访问不到后台",
    "base_tip5": "登录超时请重新登录",
    "base_tip6": "检测到您的账号在其他地方登录",
    "base_tip7": "是否关闭页面？未保存数据将不存在",
    "base_tip8": "是否下载文件",

    // #region 登录页
    "login": "用户登录",
    "signup": "注册企业账号",
    "account_login": "账号密码登录",
    "phone_login": "手机验证码登录",
    "account_ph": "请输入账号",
    "password_ph": "请输入密码",
    "phonenum_ph": "请输入手机号",
    "sms_code": "请输入验证码",
    "login_btn": "登录",
    "rememberme": "记住我",
    "account_bind_label": "密码绑定",
    "phone_bind_label": "手机绑定",
    "bind_and_login": "绑定并登录",
    "read_agree": "阅读并同意",
    "third_part": "第三方登录",
    "privacy_policy": "隐私政策",
    "wechat_login_code": "微信安全登录",

    "login_tip1": "请同意第三方协议再登录使用",
    "login_tip2": "该手机号码已绑定过第三方账号是否覆盖",
    "login_tip3": "该账号已绑定过第三方账号是否覆盖",
    "login_tip4": "请输入正确的手机号码",
    "login_tip5": "发送",

    // #endregion
    // #region Admin页面
    "frame_lang_1": "中文",
    "frame_lang_2": "English",

    "frame_edition": "企业版",
    "frame_leaveday": "到期剩余时间",
    "frame_leaveday_unit": "天",
    "frame_learning_videos": "学习视频",
    "frame_op_menu1": "修改个人信息",
    "frame_op_menu2": "修改密码",
    "frame_op_menu3": "退出登录",
    "frame_dialog_title": "修改密码",
    "frame_dialog_userName": "用户名",
    "frame_dialog_oldPassword": "原密码",
    "frame_dialog_oldPassword_ph": "原密码",
    "frame_dialog_password": "新密码",
    "frame_dialog_password_ph": "新密码",
    "frame_dialog_repassword": "确认新密码",
    "frame_dialog_repassword_ph": "确认新密码",

    "frame_dialog_user_title": "用户信息",
    "frame_dialog_user_nickname": "昵称",
    "frame_dialog_user_nickname_ph": "请输入昵称",
    "frame_dialog_user_sex": "性别",
    "frame_dialog_user_sex_ph": "请选择性别",
    "frame_dialog_user_sex_op1": "男",
    "frame_dialog_user_sex_op2": "女",
    "frame_dialog_user_phone": "手机号",
    "frame_dialog_user_phone_op1": "绑定手机",
    "frame_dialog_user_phone_op2": "解绑手机",
    "frame_dialog_user_email": "邮箱",
    "frame_dialog_user_email_ph": "请输入邮箱",
    "frame_dialog_user_bindwechat": "绑定微信",
    "frame_dialog_user_bindwechat_type1": "已绑定",
    "frame_dialog_user_bindwechat_type2": "未绑定",
    "frame_dialog_user_bindwechat_op1": "解除绑定",
    "frame_dialog_user_bindwechat_op2": "绑定微信",
    "frame_dialog_user_introduction": "个人简介",
    "frame_dialog_user_introduction_ph": "请输入个人简介",

    "frame_dialog_verify_title1": "短信验证",
    "frame_dialog_verify_title2": "绑定微信",
    "frame_dialog_verify_phone": "手机号码",
    "frame_dialog_verify_phone_ph": "请输入手机号码",
    "frame_dialog_verify_verifycode": "验证码",
    "frame_dialog_verify_verifycode_ph": "请输入验证码",

    "frame_dialog_verify_send": "发送",
    "frame_dialog_err1": "请确认新密码",
    "frame_dialog_err2": "密码必须要6位以上",
    "frame_dialog_err3": "与新密码不一致",
    "frame_dialog_err4": "请输入密码",
    "frame_dialog_err5": "请输入新密码",
    "frame_dialog_err6": "修改密码后要用新密码重新登录",
    "frame_dialog_err7": "请填写必填项",
    "frame_dialog_err8": "是否退出登录？",
    "frame_dialog_err9": "请先绑定手机号码",
    "frame_dialog_err10": "请输入正确的手机号码",
    "frame_dialog_err11": "请输入验证码",
    "frame_dialog_tip1": "是否解除其他绑定,并绑定到当前登录账户!",
    "frame_dialog_tip2": "解绑成功",
    "frame_dialog_tip3": "绑定成功",
    "frame_dialog_tip4": "您的账号剩余使用天数为",
    "frame_dialog_tip5": "天，请及时续费使用",

    // #endregion
    // #region 表格模板

    "modal_table_add": "新建",
    "modal_table_search": "查询",
    "modal_table_search_ph1": "关键字搜索",
    "modal_table_moresearch": "高级搜索",
    "modal_table_reset": "清空",

    "modal_table_index": "序号",
    "modal_table_op_edit": "编辑",
    "modal_table_op_delete": "删除",
    "modal_table_op_deletes": "批量删除",

    "modal_dialog_title": "列表详情",

    "modal_op_tip1": "请选择操作数据",
    "modal_op_tip2": "是否清空查询条件？",
    
    // #endregion
    // #region 订单列表

    "order_list_addbtn": "快排创建订单",
    "order_list_saveorder": "保存订单",
    "order_list_getCadData": "解析CAD",
    "order_list_breadcrumb": "订单列表",

    "order_list_table_orderCode": "订单编号",
    "order_list_table_customerName": "客户",
    "order_list_table_createTime": "创建时间",
    "order_list_table_createUser": "创建者",

    "order_list_detail_orderCode": "订单号",
    "order_list_detail_orderCode_ph": "请输入订单号",
    "order_list_detail_customerName": "客户",
    "order_list_detail_customerName_ph": "请输入客户",
    "order_list_detail_comments": "备注",
    "order_list_detail_comments_ph": "请输入备注",

    // #endregion
    // #region 订单数量

    "order_num_title": "订单数量",
    "order_num_top_op1": "统一数量",
    "order_num_top_op2_1": "拆分尺码",
    "order_num_top_op2_2": "合并尺码",
    "order_num_top_op3_1": "合并颜色",
    "order_num_top_op3_2": "删除颜色",
    "order_num_top_op3_3": "拆分颜色",
    "order_num_top_op4": "新增面料",
    "order_num_top_op5": "刷新",

    "order_num_top_op6": "添加款式",
    "order_num_top_op6_1_1": "手动",
    "order_num_top_op6_1_2": "录入",
    "order_num_top_op6_2_1": "上传",
    "order_num_top_op7": "新增",
    "order_num_top_op8": "删除",

    "order_num_data_fabricName": "布料名称",
    "order_num_data_component": "布料成分",
    "order_num_data_grammage": "克重",
    "order_num_data_doorframe": "计划门幅",
    "order_num_data_standby": "备次",
    "order_num_data_fabricPart": "部位",

    "order_num_table_head_1": "颜色/规格",
    "order_num_table_head_2": "色卡",
    "order_num_table_head_3": "总和",
    "order_num_table_head_4": "添加颜色",
    "order_num_table_head_5": "合计",
    "order_num_table_head_color_ph": "请输入颜色",
    "order_num_table_head_upload_1": "上传色卡",

    "order_num_table_bottom_op1": "保存当前款",
    "order_num_table_bottom_op2": "保存所有款",

    "order_num_dialog_title": "列表详情",

    "order_num_tip1": "复制成功",
    "order_num_tip2": "开启统一数量会重置辅料数量",
    "order_num_tip3": "是否删除该面料？",
    "order_num_tip4": "是否删除款式",
    "order_num_tip5": "是否重新上传色卡？",

    // #endregion
    // #region 订单款式详情

    "order_style_info_styleNo": "款号",
    "order_style_info_styleNo_ph": "请输入款号/自动生成",
    "order_style_info_style": "款式",
    "order_style_info_style_ph": "请输入款式",
    "order_style_info_po": "PO",
    "order_style_info_po_ph": "请输入PO",
    "order_style_info_deliveryDate": "交货日期",
    "order_style_info_deliveryDate_ph": "请选择交货日期",
    "order_style_info_stylePic": "款式图",
    "order_style_info_comments": "备注",
    "order_style_info_comments_ph": "请输入备注",

    // #endregion
    // #region 订单尺码信息

    "order_size_info_title": "尺码选择",
    "order_size_info_edit": "编辑",
    "order_size_info_delete": "删除",
    "order_size_info_setsize": "生成尺码",
    "order_size_info_reset": "清空表格",
    "order_size_info_diymodal": "自定义模板",

    "order_size_table_title_size": "尺码",

    "order_size_table_tip1": "上移尺码",
    "order_size_table_tip2": "下移尺码",
    "order_size_table_tip3": "新增尺码",
    "order_size_table_tip4": "删除尺码",

    "order_size_dialog_title": "尺码模板",

    "order_size_sizetable_combinationName": "自定义名称",
    "order_size_sizetable_combinationName_ph": "请输入自定义名称",
    "order_size_sizetable_sizeData": "自定义尺码",
    "order_size_sizetable_sizeData_ph": "请输入自定义尺码（按逗号隔开 例如：S,M,L,XL）",

    "order_size_tip1": "是否删除尺码模板？",
    "order_size_tip2": "保存新尺码会拆分所有合并尺码数据，确定保存吗？",

    // #endregion
    // #region 订单款式信息

    "order_style_styleNo": "款号",
    "order_style_style": "款式",
    "order_style_deliveryDate": "交货日期",
    "order_style_edit": "编辑",
    "order_style_size": "尺码",
    "order_style_dialog_title": "列表详情",

    // #endregion
    // #region 订单CAD上传

    "order_style_cadupload_title": "选择CAD文件",
    "order_style_cadupload_op1": "选择文件",
    "order_style_cadupload_btn1": "添加",
    "order_style_cadupload_btn2": "删除",

    // #endregion
    // #region 配比方案

    "planlist_index_title": "配比方案列表",

    "planlist_index_status_0": "等待中",
    "planlist_index_status_1": "计算中",
    "planlist_index_status_2": "已生成",
    "planlist_index_status_3": "仅保存",
    "planlist_index_status_4": "无结果",
    "planlist_index_btn1": "方案列表",
    "planlist_index_btn2": "默认值设置",
    "planlist_index_btn3": "保存",
    "planlist_index_btn4": "开始运算",
    "planlist_index_btn5": "新建运算",
    "planlist_index_btn6": "去排料",

    "planlist_index_table_styleFabricInfo": "款号-布料",
    "planlist_index_table_colorName": "颜色",
    "planlist_index_table_createTime": "运算时间",
    "planlist_index_table_ratioCount": "配比数",
    "planlist_index_table_bedCount": "裁剪床数",
    "planlist_index_table_cutCount": "裁剪件数",
    "planlist_index_table_layerCount": "排版件数",
    "planlist_index_table_status": "配比状态",

    "planlist_index_dialog_title1": "方案列表",
    "planlist_index_dialog_title2": "订单号",
    "planlist_index_dialog_title3": "客户名称",

    "planlist_index_dialog_title4": "配比测算",

    // #endregion
    // #region 新增配比方案

    "planlist_add_detail_doorframe": "门幅",
    "planlist_add_detail_grammage": "克重",
    "planlist_add_detail_component": "成分",

    "planlist_add_detail_tip1": "暂无该订单的款式以及布料数据",

    "planlist_add_detail_btn1": "生成表格视图",

    "planlist_add_detail_table_title1": "颜色",
    "planlist_add_detail_table_title2": "顺毛",
    "planlist_add_detail_table_title3": "合计",
    "planlist_add_detail_table_title4": "层数",
    "planlist_add_detail_table_title5": "件数",

    "planlist_add_detail_table_tip1": "排料件数",
    "planlist_add_detail_table_tip1_more": "排料图排版的最多件数与最少件数，件数多少由台板长度决定",
    "planlist_add_detail_table_tip2": "铺布层数",
    "planlist_add_detail_table_tip2_more": "裁剪一床的最多层数不能超过能裁剪的高度",
    "planlist_add_detail_table_tip3": "误差件数",
    "planlist_add_detail_table_tip3_more": "一个规格/尺码允许多裁少裁的件数",
    "planlist_add_detail_table_tip4": "打包数",
    "planlist_add_detail_table_tip5": "同规格件数",
    "planlist_add_detail_table_tip5_more": "同一个规格/尺码排料的件数要求",
    "planlist_add_detail_table_tip6": "排料规格数",
    "planlist_add_detail_table_tip6_more": "参与排料的规格/尺码数量要求",
    "planlist_add_detail_table_tip7": "开片数",
    "planlist_add_detail_table_tip7_more": "布料门幅能排大身衣片的片数",
    
    "planlist_add_detail_table_tip8_ph": "请选择开片数",
    "planlist_add_detail_table_tip8_op1": "任意",
    "planlist_add_detail_table_tip8_op2": "2片",
    "planlist_add_detail_table_tip8_op3": "3片",
    "planlist_add_detail_table_tip8_op4": "4片",
    "planlist_add_detail_table_tip8_op5": "5片",
    "planlist_add_detail_table_tip9": "台板长度",
    "planlist_add_detail_table_tip10": "配比类型",
    "planlist_add_detail_table_tip10_op1": "常规",
    "planlist_add_detail_table_tip10_op2": "单码",
    "planlist_add_detail_table_tip10_op3": "双码",
    "planlist_add_detail_table_tip10_op4": "顺倒毛",
    "planlist_add_detail_table_tip10_op5": "改码",
    "planlist_add_detail_table_tip11": "算法",
    "planlist_add_detail_table_tip11_op1": "快速",
    "planlist_add_detail_table_tip11_op2": "智能",
    "planlist_add_detail_table_tip11_op3": "超级",
    "planlist_add_detail_table_tip12": "是否打箱",
    "planlist_add_detail_table_tip12_op1": "现有配比",
    "planlist_add_detail_table_tip12_op2": "固定配比",

    "planlist_add_detail_table_title": "排料设置",
    // #region ***************重点翻译专业术语
    "planlist_add_detail_table_tip13": "排料方式",
    "planlist_add_detail_table_tip13_ph": "请选择排料方式",
    "planlist_add_detail_table_tip13_op1": "混排单项",
    "planlist_add_detail_table_tip13_op2": "同码同向",
    "planlist_add_detail_table_tip13_op3": "件份相同",
    "planlist_add_detail_table_tip14": "铺布方式",
    "planlist_add_detail_table_tip14_ph": "请选择铺布方式",
    "planlist_add_detail_table_tip14_op1": "平铺",
    "planlist_add_detail_table_tip14_op2": "合掌",
    "planlist_add_detail_table_tip15": "衣片间隔X",
    "planlist_add_detail_table_tip16": "衣片间隔Y",
    "planlist_add_detail_table_tip17": "经向缩水",
    "planlist_add_detail_table_tip18": "纬向缩水",

    "planlist_add_detail_table_tip99": "分页长度",
    "planlist_add_detail_table_tip98": "分页间隔",
    "planlist_add_detail_table_tip97": "排料时间",
    "planlist_add_detail_table_tip96": "分钟",
    "planlist_add_detail_table_tip95": "旋转角度",
    "planlist_add_detail_table_tip95_ph": "请选择旋转角度",
    "planlist_add_detail_table_tip94": "倾斜",
    "planlist_add_detail_table_tip93": "压角",
    "planlist_add_detail_table_tip92": "布边距",
    "planlist_add_detail_table_tip91": "缩放系数",
    "planlist_add_detail_table_tip90": "是否对称片横排",
    // #endregion

    "planlist_add_detail_table_tip19": "初始值设置",

    "planlist_add_detail_table_tip20_1": "请确保",
    "planlist_add_detail_table_tip20_2": "和",
    "planlist_add_detail_table_tip20_3": "颜色数量相同",
    "planlist_add_detail_table_tip20_4": "颜色种类相同",
    "planlist_add_detail_table_tip20_5": "布料克重相同",
    "planlist_add_detail_table_tip20_6": "布料成分相同",
    "planlist_add_detail_table_tip21": "请先生成正确表格视图",
    "planlist_add_detail_table_tip22": "是否开启强制配比",
    "planlist_add_detail_table_tip23": "请生成正确表格视图再提交保存数据",
    "planlist_add_detail_table_tip24": "保存成功！",
    "planlist_add_detail_table_tip25": "请生成正确表格视图再进行运算",
    "planlist_add_detail_table_tip26": "请生成正确表格视图再进行新建运算",

    "planlist_add_detail_yes": "是",
    "planlist_add_detail_no": "否",

    // 高低层
    "planlist_add_detail_lh_title": "高低层",
    "planlist_add_detail_lh_table_1": "序号",
    "planlist_add_detail_lh_table_2": "配比数",
    "planlist_add_detail_lh_table_2_ph": "请选择配比",
    "planlist_add_detail_lh_table_3": "新增高低层",
    "planlist_add_detail_lh_table_4": "删除高低层",
    "planlist_add_detail_lh_table_5": "暂无多余配比提供设置高低层",

    // 排料方案参数
    "planlist_add_detail_nestset_op1": "重新连接",
    "planlist_add_detail_nestset_op2": "取消任务",
    
    "planlist_add_detail_nestset_title1": "件数",
    "planlist_add_detail_nestset_title2": "层数",
    "planlist_add_detail_nestset_title3": "床数",
    "planlist_add_detail_nestset_title4": "裁剪件数",
    "planlist_add_detail_nestset_title5": "打包数",
    "planlist_add_detail_nestset_title6": "方案",
    "planlist_add_detail_nestset_title7": "增裁数",
    "planlist_add_detail_nestset_title8": "规格",

    // #region ***************重点翻译专业术语
    "planlist_add_detail_nestset_drec_1": "混排",
    "planlist_add_detail_nestset_drec_2": "同码同向",
    "planlist_add_detail_nestset_drec_3": "件份相同",
    "planlist_add_detail_nestset_drec_4": "单一方向",
    "planlist_add_detail_nestset_drec_5": "同码同向",
    "planlist_add_detail_nestset_drec_6": "尺码相同",
    "planlist_add_detail_nestset_drec_7": "省料优先",
    "planlist_add_detail_nestset_drecTypeName_op1": "双向排料",
    "planlist_add_detail_nestset_drecTypeName_op2": "单向排料",
    // #endregion

    "planlist_add_detail_nestset_tip1": "是否取消运算",
    "planlist_add_detail_nestset_tip2": "加入成功",
    "planlist_add_detail_nestset_tip3": "暂无可用方案可加入排料",

    "planlist_add_detail_nestset_nodata": "无计算结果",

    "planlist_add_detail_nestset_setlh": "设计高低层",
    "planlist_add_detail_nestset_drecTypeName_ph": "请选择排料规则",

    // #endregion
    // #region 排料方案列表

    "schemelist_table_breadcrumb": "排料方案列表",

    "schemelist_table_fabricInfo": "款号-布料",
    "schemelist_table_colorNameArr": "颜色",
    "schemelist_table_component": "成分-克重",
    "schemelist_table_schemeName": "方案名称",
    "schemelist_table_status": "状态",
    "schemelist_table_createTime": "选择时间",
    "schemelist_table_ratioCount": "配比数",
    "schemelist_table_totalBedCount": "床数",
    "schemelist_table_totalLayerCount": "层数",
    "schemelist_table_totalCutCount": "裁剪件数",
    "schemelist_table_totalQty": "总件数",

    "schemelist_table_btn1": "生成采购单",
    "schemelist_table_btn2": "重新生成采购单",
    "schemelist_table_btn3": "生成采购单",
    "schemelist_table_btn4": "重新排料",

    "schemelist_table_tag1": "等待中",
    "schemelist_table_tag2": "部分完成",
    "schemelist_table_tag3": "排料完成",

    "schemelist_table_tip1": "请选择需要生成的采购单数据",
    "schemelist_table_tip2": "是否覆盖已有采购单？",
    "schemelist_table_tip3": "第",
    "schemelist_table_tip4": "条有数据没有完成排料，请全部数据完成排料后生成采购单！",
    "schemelist_table_tip5": "方案信息",

    // #endregion
    // #region 方案详情页

    "scheme_detail_title1": "排料件数",
    "scheme_detail_title2": "铺布层数",
    "scheme_detail_title3": "误差件数",
    "scheme_detail_title4": "同规格件数",
    "scheme_detail_title5": "开片数",
    "scheme_detail_title5_op1": "任意",
    "scheme_detail_title5_op2": "2片",
    "scheme_detail_title5_op3": "3片",
    "scheme_detail_title5_op4": "4片",
    "scheme_detail_title5_op5": "5片",
    "scheme_detail_title6": "排料规格数",
    "scheme_detail_title7": "打包数",
    "scheme_detail_title8": "台板长度",
    "scheme_detail_title9": "配比类型",
    "scheme_detail_title9_op1": "常规",
    "scheme_detail_title9_op2": "单码",
    "scheme_detail_title9_op3": "双码",
    "scheme_detail_title9_op4": "顺倒毛",
    "scheme_detail_title9_op5": "改码",
    "scheme_detail_title10": "算法",
    "scheme_detail_title10_op1": "快速",
    "scheme_detail_title10_op2": "智能",
    "scheme_detail_title10_op3": "超级",
    "scheme_detail_title11": "现有配比",
    "scheme_detail_title12": "固定配比",

    "scheme_detail_yes": "是",
    "scheme_detail_no": "否",

    "scheme_detail_table_title1": "颜色",
    "scheme_detail_table_title2": "合计",

    "scheme_detail_table_title3": "布料成分",
    "scheme_detail_table_title4": "克重",
    "scheme_detail_table_title5": "门幅",
    "scheme_detail_table_title6": "单位",
    "scheme_detail_table_title6_ph": "请选择单位",

    "scheme_detail_table_title7": "配比",
    "scheme_detail_table_title8": "小计(件)",
    "scheme_detail_table_title9": "层数",
    "scheme_detail_table_title10": "净",
    "scheme_detail_table_title11": "单层",
    "scheme_detail_table_title12": "单件",
    "scheme_detail_table_title13": "数",

    "scheme_detail_table_title14": "排料长度",
    "scheme_detail_table_title14_ph": "输入长度",
    "scheme_detail_table_title15": "排料宽度",
    "scheme_detail_table_title15_ph": "输入宽度",
    "scheme_detail_table_title16": "用料率",
    "scheme_detail_table_title17": "排料图",
    "scheme_detail_table_title18": "暂无麦架文件",
    "scheme_detail_table_title19": "下载麦架文件",
    "scheme_detail_table_title20": "暂无裁剪文件",
    "scheme_detail_table_title21": "下载裁剪文件",

    "scheme_detail_tip1": "误差",
    "scheme_detail_tip2": "是否覆盖已有采购单？",
    "scheme_detail_tip3": "该方案已生成【采购单】，重新排料会【清空相应采购单】数据，是否重新排料？",
    "scheme_detail_tip4": "重新排料会失去当前已有排料数据，是否重新排料",

    // #endregion
    // #region 采购单列表数据

    "planbuy_index_title1": "采购单",
    "planbuy_index_title2": "采购任务单",
    "planbuy_index_title3": "采购方案",

    "planbuy_index_btn1": "实际采购",
    "planbuy_index_btn2": "查看任务单",
    "planbuy_index_btn3": "生成任务单",
    "planbuy_index_btn4": "反审核",
    "planbuy_index_btn5": "录入布料成分",
    "planbuy_index_btn6": "不录入布料成分",

    "planbuy_index_colours": "颜色",
    "planbuy_index_fabricName": "布料名称",
    "planbuy_index_component": "布料成分",
    "planbuy_index_doorframe": "门幅",
    "planbuy_index_grammage": "克重",
    "planbuy_index_unit": "单位",
    "planbuy_index_createTime": "生成时间",
    "planbuy_index_createUser": "创建者",

    // #endregion
    // #region 采购单详情

    "planbuy_detail_title1": "布料名称",
    "planbuy_detail_title2": "布料成分",
    "planbuy_detail_title3": "克重",
    "planbuy_detail_title3_ph": "克重",
    "planbuy_detail_title4": "铺布余量",
    "planbuy_detail_title4_ph": "输入余量",
    "planbuy_detail_title5": "割带长/宽",
    "planbuy_detail_title5_ph1": "长度",
    "planbuy_detail_title5_ph2": "宽度",
    "planbuy_detail_title5_ph3": "件",
    "planbuy_detail_title6": "布头尾损耗",
    "planbuy_detail_title7": "布边宽度",
    "planbuy_detail_title8": "单位",
    "planbuy_detail_title8_ph": "请选择单位",

    "planbuy_detail_table_title1": "单层",
    "planbuy_detail_table_title2": "规格",
    "planbuy_detail_table_title3": "小计",
    "planbuy_detail_table_title4": "排料图",
    "planbuy_detail_table_title5": "排料长度",
    "planbuy_detail_table_title6": "排料宽度",
    "planbuy_detail_table_title7": "用料率",
    "planbuy_detail_table_title8": "层数",
    "planbuy_detail_table_title9": "单层",
    "planbuy_detail_table_title10": "净",
    "planbuy_detail_table_title11": "割带",
    "planbuy_detail_table_title12": "毛",
    "planbuy_detail_table_title13": "单件",

    "planbuy_detail_table_title14": "颜色/配比",
    "planbuy_detail_table_title15": "织造损耗",
    "planbuy_detail_table_title16": "查看配比信息",
    "planbuy_detail_table_title17": "印染损耗",
    "planbuy_detail_table_title18": "裁剪损耗",
    "planbuy_detail_table_title18_1": "数量",

    "planbuy_detail_table_title19": "单件用料",
    "planbuy_detail_table_title20": "单价",
    "planbuy_detail_table_title20_ph": "输入单价",
    "planbuy_detail_table_title21": "元",
    "planbuy_detail_table_title22": "单件成本",
    "planbuy_detail_table_title23": "总价",
    "planbuy_detail_table_title24": "各色总计",

    "planbuy_detail_tip1": "保存成功",
    "planbuy_detail_tip2": "审核通过",
    "planbuy_detail_tip3": "反审核后采购任务单数据将被清空，是否继续？",
    "planbuy_detail_tip4": "反审核通过",

    // #endregion
    // #region 采购任务单

    "planbuy_mission_title1": "布料名称",
    "planbuy_mission_title2": "布料成分",
    "planbuy_mission_title3": "铺布余量",
    "planbuy_mission_title4": "总件数",
    "planbuy_mission_title5": "割带长/宽",
    "planbuy_mission_title6": "布头尾损耗",
    "planbuy_mission_title7": "布边宽度",
    "planbuy_mission_title8": "单位",
    "planbuy_mission_title9": "织造损耗",
    "planbuy_mission_title10": "印染损耗",
    "planbuy_mission_title11": "裁剪损耗",

    "planbuy_mission_title12": "配比名",
    "planbuy_mission_title13": "布料个数",
    "planbuy_mission_title14": "布料明细",
    "planbuy_mission_title15": "门幅",
    "planbuy_mission_title16": "克重",
    "planbuy_mission_title17": "缸号",
    "planbuy_mission_title18": "单布料波动",
    "planbuy_mission_title19": "单配比用料",
    "planbuy_mission_title20": "成分名称",
    "planbuy_mission_title20_ph": "请输入成分名称",
    "planbuy_mission_title21": "比例",
    "planbuy_mission_title21_ph": "请输入比例",
    "planbuy_mission_title22": "采购数量",
    "planbuy_mission_title23": "新增布料成分",
    "planbuy_mission_title24": "删除布料成分",

    // #endregion
    // #region 实际采购数据

    "planbuy_real_title1": "颜色",
    "planbuy_real_title2": "原计划采购",
    "planbuy_real_title3": "实际采购",
    "planbuy_real_title3_ph": "请输入实际采购数",
    "planbuy_real_title4": "原计划生产件数",
    "planbuy_real_title5": "预计实际生产数",
    "planbuy_real_title6": "预产成率",
    "planbuy_real_title7": "件",

    "planbuy_real_tip1": "保存成功",

    // #endregion
    // #region 辅料单数据

    "support_index_title1": "辅料单",
    "support_index_title2": "款号",
    "support_index_title3": "款式",
    "support_index_title4": "PO",
    "support_index_title5": "交货日期",

    "support_index_title6": "名称",
    "support_index_title7": "单位",
    "support_index_title8": "规格",
    "support_index_title9": "数量",
    "support_index_title10": "用量/件",
    "support_index_title11": "合计",
    "support_index_title12": "使用部位",
    "support_index_title13": "品质要求",
    "support_index_title14": "图片",

    "support_index_btn1": "编辑",

    // #endregion
    // #region 新增辅料单数据

    "support_detail_title1": "配置选择",
    "support_detail_title1_ph": "请选择默认配置",
    "support_detail_title2": "自定义",
    "support_detail_title3": "客户",
    "support_detail_title3_ph": "请输入客户",
    "support_detail_title4": "品名",
    "support_detail_title4_ph": "请输入品名",
    "support_detail_title5": "款号",
    "support_detail_title5_ph": "请输入款号",
    "support_detail_title6": "PO",
    "support_detail_title6_ph": "请输入PO",
    "support_detail_title7": "订单号",
    "support_detail_title7_ph": "请输入订单号",
    "support_detail_title8": "数量",
    "support_detail_title8_ph": "请输入数量",

    "support_detail_title9": "名称",
    "support_detail_title9_ph": "请输入名称",
    "support_detail_title10": "单位",
    "support_detail_title10_ph": "请输入单位",
    "support_detail_title11": "规格",
    "support_detail_title11_ph": "请输入规格",
    "support_detail_title12": "数量",
    "support_detail_title12_ph": "请输入数量",
    "support_detail_title13": "用量/件",
    "support_detail_title13_ph": "请输入用量(件)",
    "support_detail_title14": "合计",
    "support_detail_title15": "使用部位",
    "support_detail_title15_ph": "请输入使用部位",
    "support_detail_title16": "品质要求",
    "support_detail_title16_ph": "请输入品质要求",
    "support_detail_title17": "图片",
    "support_detail_title18": "配置模板",
    "support_detail_title19": "是否删除基础配制",
    "support_detail_title20": "是否删除该行",
    "support_detail_title21": "是否重新上传色卡？",

    "support_detail_btn1": "编辑",
    "support_detail_btn2": "删除",
    "support_detail_btn3": "添加自定义基础配置",
    "support_detail_btn4": "上传图片",

    "support_detail_tip1": "保存成功",

    // #endregion
    // #region 辅料单模板页

    "support_template_title1": "自定义名称",
    "support_template_title1_ph": "请输入自定义名称",
    "support_template_title2": "名称",
    "support_template_title2_ph": "请输入名称",
    "support_template_title3": "单位",
    "support_template_title3_ph": "请输入单位",

    "support_template_tip1": "保存成功",

    // #endregion
    // #region 用户信息列表

    "user_index_title": "用户列表",

    "user_index_title1": "男",
    "user_index_title2": "女",
    "user_index_title3": "其他",

    "user_index_tag1": "正常",
    "user_index_tag2": "冻结",

    "user_index_table_userName": "账号",
    "user_index_table_nickName": "昵称",
    "user_index_table_createTime": "注册时间",
    "user_index_table_sex": "性别",
    "user_index_table_phone": "手机号",
    "user_index_table_email": "邮箱",
    "user_index_table_introduction": "个人简介",
    "user_index_table_state": "状态",

    "user_index_tip1": "用户信息",

    // #endregion
    // #region 用户信息详情

    "user_detail_title1": "账号",
    "user_detail_title1_ph": "请输入账号",
    "user_detail_title2": "密码",
    "user_detail_title2_ph": "请输入密码",
    "user_detail_title3": "昵称",
    "user_detail_title3_ph": "请输入昵称",
    "user_detail_title4": "性别",
    "user_detail_title4_ph": "请选择性别",
    "user_detail_title5": "手机号",
    "user_detail_title5_ph": "请输入手机号",
    "user_detail_title6": "邮箱",
    "user_detail_title6_ph": "请输入邮箱",
    "user_detail_title7": "状态",
    "user_detail_title7_ph": "请选择状态",
    "user_detail_title8": "角色",
    "user_detail_title8_ph": "请选择角色",
    "user_detail_title9": "绑定微信",
    "user_detail_title9_btn1": "解除绑定",
    "user_detail_title9_tag1": "已绑定",
    "user_detail_title9_tag2": "未绑定",
    "user_detail_title10": "个人简介",
    "user_detail_title10_ph": "请输入个人简介",
    
    "user_detail_title11": "手机号码",
    "user_detail_title12": "验证码",
    "user_detail_title12_ph": "请输入验证码",
    "user_detail_title13": "短信验证",

    "user_detail_sex_op1": "男",
    "user_detail_sex_op2": "女",
    "user_detail_state_op1": "正常",
    "user_detail_state_op2": "冻结",

    "user_detail_btn1": "发送",

    "user_detail_tip1": "请先绑定手机号码",
    "user_detail_tip2": "请输入正确的手机号码",
    "user_detail_tip3": "解绑成功",
    "user_detail_tip4": "保存成功",

    // #endregion
    // #region 角色

    "role_list_title1": "角色列表",
    "role_list_title2": "角色信息",

    "role_list_title3": "角色名称",
    "role_list_title3_ph": "请输入角色名称",
    "role_list_title4": "菜单列表",

    "role_list_table_roleName": "账号",
    "role_list_table_isAdmin": "是否为系统角色",

    "role_list_yes": "是",
    "role_list_no": "否",

    "role_list_btn1": "绑定菜单",
    "role_list_btn2": "绑定",

    "role_list_tip1": "加入成功",

    // #endregion
    // #region 系统设置

    "sys_index_title1": "系统设置",

    "sys_index_ph1": "请输入值",
    "sys_index_ph2": "请选择值",
    "sys_index_ph3": "请选择日期",

    // #endregion
    // #region 企业数据

    "store_detail_enterpriseName": "企业名称",
    "store_detail_enterpriseName_ph": "请输入企业名称",
    "store_detail_passWord": "登录密码",
    "store_detail_passWord_ph": "请输入登录密码",
    "store_detail_rePassWord": "确认密码",
    "store_detail_rePassWord_ph": "请确认密码",
    "store_detail_enterpriseMobile": "注册手机号",
    "store_detail_enterpriseMobile_ph": "请输入企业注册手机号",
    "store_detail_smsCode": "验证码",
    "store_detail_smsCode_ph": "请输入验证码",

    "store_detail_tip1": "我已阅读并同意",
    "store_detail_tip2": "注册完后请使用 手机号作为账号 登录",
    "store_detail_tip3": "发送",
    "store_detail_tip4": "请填写企业注册手机以获取验证码",
    "store_detail_tip5": "请填写表单中信息",
    "store_detail_tip6": "两次密码不匹配，请重新确认密码",
    "store_detail_tip7": "密码长度必须在6~8位之间",
    "store_detail_tip8": "密码必须由数字与字符组成",
    "store_detail_tip9": "请阅读并同意服务条款后，再注册使用",

    // #endregion

  },
  // #endregion
  // #region ================== 英文 =================
  "en": {
    // 弹窗
    "dialog_close": "Close",
    "dialog_save": "Save",
    "dialog_check": "Ok",
    "dialog_cancel": "Cancel",
    "confirm_tip": "Tip",
    "aside_menu_title": "OrderCode",
    "table_operation": "Operation",
    "system_lang_change": "正在切换语言中······",
    // 单位
    "unit_weight": " weight",
    "unit_yardage": " length",
    "unit_inch": " length",
    "unit_meter": " length",

    "unit_1": "kg",
    "unit_2": "m",
    "unit_3": "yd",
    "unit_4": "in",
    // 自动补充提示
    "unit_auto": [{
      value: 'm'
    }, {
      value: 'dm'
    }, {
      value: 'cm'
    }, {
      value: 'mm'
    }, {
      value: 'yd'
    }, {
      value: 'in'
    }, {
      value: 'kg'
    }, {
      value: 'g'
    }],
    // 基础
    "base_tip1": "Do you want to perform this operation",
    "base_tip2": "Detailing……",
    "base_tip3": "Please contact the administrator",
    "base_tip4": "Network connection error",
    "base_tip5": "Login timeout, please login again",
    "base_tip6": "It is detected that your account is logged in elsewhere",
    "base_tip7": "Close page? Unsaved data will not exist",
    "base_tip8": "If Download File",

    // #region 登录页
    "login": "Sign In",
    "signup": "Sign Up",
    "account_login": "Account Login",
    "phone_login": "Phone Login",
    "account_ph": "Account",
    "password_ph": "Password",
    "phonenum_ph": "Phone",
    "sms_code": "Code",
    "login_btn": "Login",
    "rememberme": "Remember",
    "account_bind_label": "Account Bind",
    "phone_bind_label": "Phone Bind",
    "bind_and_login": "Bind&Login",
    "read_agree": "Read&Agree",
    "third_part": "Third-party login",
    "privacy_policy": "Privacy Policy",
    "wechat_login_code": "WeChat secure login",

    "login_tip1": "Please agree to the third-party agreement before logging in for use",
    "login_tip2": "Has this phone number been linked to a third-party account? Is it covered",
    "login_tip3": "Has this account been linked to a third-party account? Is it covered",
    "login_tip4": "Please enter the correct phone number",
    "login_tip5": "Send",

    // #endregion
    // #region Admin页面
    "frame_lang_1": "中文",
    "frame_lang_2": "English",

    "frame_edition": "Enterprise",
    "frame_leaveday": "Remaining usage time",
    "frame_leaveday_unit": "Days",
    "frame_learning_videos": "Learning videos",
    "frame_op_menu1": "Modify personal information",
    "frame_op_menu2": "Edit password",
    "frame_op_menu3": "Sign Out",
    "frame_dialog_title": "Edit Password",
    "frame_dialog_userName": "UserName",
    "frame_dialog_oldPassword": "Old PWD",
    "frame_dialog_oldPassword_ph": "Old Password",
    "frame_dialog_password": "New PWD",
    "frame_dialog_password_ph": "New Password",
    "frame_dialog_repassword": "Check PWD",
    "frame_dialog_repassword_ph": "Check New Password",

    "frame_dialog_user_title": "User Info",
    "frame_dialog_user_nickname": "NickName",
    "frame_dialog_user_nickname_ph": "NickName",
    "frame_dialog_user_sex": "Sex",
    "frame_dialog_user_sex_ph": "Sex",
    "frame_dialog_user_sex_op1": "male",
    "frame_dialog_user_sex_op2": "female",
    "frame_dialog_user_phone": "Phone",
    "frame_dialog_user_phone_op1": "Bind",
    "frame_dialog_user_phone_op2": "Unbind",
    "frame_dialog_user_email": "Email",
    "frame_dialog_user_email_ph": "Email",
    "frame_dialog_user_bindwechat": "Bind Wechat",
    "frame_dialog_user_bindwechat_type1": "Binded",
    "frame_dialog_user_bindwechat_type2": "Unbind",
    "frame_dialog_user_bindwechat_op1": "Unbind",
    "frame_dialog_user_bindwechat_op2": "Bind Wechat",
    "frame_dialog_user_introduction": "Introduction",
    "frame_dialog_user_introduction_ph": "Introduction",

    "frame_dialog_verify_title1": "SMSVerify",
    "frame_dialog_verify_title2": "Bind Wechat",
    "frame_dialog_verify_phone": "Phone",
    "frame_dialog_verify_phone_ph": "Phone",
    "frame_dialog_verify_verifycode": "Code",
    "frame_dialog_verify_verifycode_ph": "VerifyCode",

    "frame_dialog_verify_send": "Send",
    "frame_dialog_err1": "Pleace check new password",
    "frame_dialog_err2": "The password must be at least 6 characters long",
    "frame_dialog_err3": "Inconsistent with the new password",
    "frame_dialog_err4": "Please input a password",
    "frame_dialog_err5": "Please input a new password",
    "frame_dialog_err6": "After changing the password, you need to log in again with the new password",
    "frame_dialog_err7": "Please fill in the required fields",
    "frame_dialog_err8": "Do you want to log out?",
    "frame_dialog_err9": "Please bind your phone number first",
    "frame_dialog_err10": "Please enter the correct phone number",
    "frame_dialog_err11": "Please enter the verification code",
    "frame_dialog_tip1": "Do you want to unbind other bindings and bind them to the current login account!",
    "frame_dialog_tip2": "Unbind successfully",
    "frame_dialog_tip3": "Bind successfully",
    "frame_dialog_tip4": "The remaining usage days of your account are",
    "frame_dialog_tip5": "days，Please renew and use in a timely manner",

    // #endregion
    // #region 表格模板
    
    "modal_table_add": "Add",
    "modal_table_search": "Search",
    "modal_table_search_ph1": "Keyword Search",
    "modal_table_moresearch": "Adv Search",
    "modal_table_reset": "Reset",

    "modal_table_index": "No.",
    "modal_table_op_edit": "Edit",
    "modal_table_op_delete": "Del",
    "modal_table_op_deletes": "Batch deletion",
    
    "modal_dialog_title": "Detail",

    "modal_op_tip1": "Please select operational data",
    "modal_op_tip2": "Do you want to clear the query criteria?",
    
    // #endregion
    // #region 订单列表

    "order_list_addbtn": "KP Create Order",
    "order_list_saveorder": "Save Order",
    "order_list_getCadData": "Analyze CAD",
    "order_list_breadcrumb": "Order List",

    "order_list_table_orderCode": "Order Code",
    "order_list_table_customerName": "Customer",
    "order_list_table_createTime": "Create Time",
    "order_list_table_createUser": "Create User",

    "order_list_detail_orderCode": "Order Code",
    "order_list_detail_orderCode_ph": "Pleace input a order code",
    "order_list_detail_customerName": "Customer",
    "order_list_detail_customerName_ph": "Pleace input a customer name",
    "order_list_detail_comments": "Comments",
    "order_list_detail_comments_ph": "Pleace input the comments",

    // #endregion
    // #region 订单数量

    "order_num_title": "Order Number",
    "order_num_top_op1": "Unified quantity",
    "order_num_top_op2_1": "Split size",
    "order_num_top_op2_2": "Merge size",
    "order_num_top_op3_1": "Merge color",
    "order_num_top_op3_2": "Del color",
    "order_num_top_op3_3": "Split color",
    "order_num_top_op4": "Add fabric",
    "order_num_top_op5": "refresh",

    "order_num_top_op6": "Add style",
    "order_num_top_op6_1_1": "Manual",
    "order_num_top_op6_1_2": "Add",
    "order_num_top_op6_2_1": "Upload",
    "order_num_top_op7": "Add",
    "order_num_top_op8": "Del",

    "order_num_data_fabricName": "FabricName",
    "order_num_data_component": "Component",
    "order_num_data_grammage": "Grammage",
    "order_num_data_doorframe": "Doorframe",
    "order_num_data_standby": "Standby",
    "order_num_data_fabricPart": "FabricPart",

    "order_num_table_head_1": "Color/Spec",
    "order_num_table_head_2": "Color card",
    "order_num_table_head_3": "Sum",
    "order_num_table_head_4": "Add Color",
    "order_num_table_head_5": "Total",
    "order_num_table_head_color_ph": "Pleace input the color",
    "order_num_table_head_upload_1": "Upload Color Card",

    "order_num_table_bottom_op1": "Save current data",
    "order_num_table_bottom_op2": "Save all data",

    "order_num_dialog_title": "Detail",

    "order_num_tip1": "Copy success",
    "order_num_tip2": "Enabling a unified quantity will reset the quantity of auxiliary materials",
    "order_num_tip3": "Do you want to delete this fabric?",
    "order_num_tip4": "Do you want to delete the style",
    "order_num_tip5": "Do you want to upload the color card again?",

    // #endregion
    // #region 订单款式详情

    "order_style_info_styleNo": "StyleNo",
    "order_style_info_styleNo_ph": "Pleace input the styleNo/Automatically generated",
    "order_style_info_style": "Style",
    "order_style_info_style_ph": "Pleace input the style",
    "order_style_info_po": "PO",
    "order_style_info_po_ph": "Pleace input the PO",
    "order_style_info_deliveryDate": "DeliveryDate",
    "order_style_info_deliveryDate_ph": "Please select delivery date",
    "order_style_info_stylePic": "StylePic",
    "order_style_info_comments": "Comments",
    "order_style_info_comments_ph": "Pleace input the comments",

    // #endregion
    // #region 订单尺码信息

    "order_size_info_title": "Size selection",
    "order_size_info_edit": "Edit",
    "order_size_info_delete": "Del",
    "order_size_info_setsize": "Generate size",
    "order_size_info_reset": "Clear the table",
    "order_size_info_diymodal": "Custom Template",

    "order_size_table_title_size": "Size",

    "order_size_table_tip1": "Move up size",
    "order_size_table_tip2": "Move down size",
    "order_size_table_tip3": "Add Size",
    "order_size_table_tip4": "Del Size",

    "order_size_dialog_title": "Size Template",

    "order_size_sizetable_combinationName": "CustomName",
    "order_size_sizetable_combinationName_ph": "Pleace input the CustomName",
    "order_size_sizetable_sizeData": "CustomSize",
    "order_size_sizetable_sizeData_ph": "Pleace input the CustomSize（ex.：S,M,L,XL）",

    "order_size_tip1": "Do you want to delete the size template?",
    "order_size_tip2": "Saving a new size will split all merged size data. Are you sure you want to save it?",

    // #endregion
    // #region 订单款式信息

    "order_style_styleNo": "StyleNo",
    "order_style_style": "Style",
    "order_style_deliveryDate": "DeliveryDate",
    "order_style_edit": "Edit",
    "order_style_size": "Sizes",
    "order_style_dialog_title": "Detail",

    // #endregion
    // #region 订单CAD上传

    "order_style_cadupload_title": "Select CADfile",
    "order_style_cadupload_op1": "Select CADfile",
    "order_style_cadupload_btn1": "Add",
    "order_style_cadupload_btn2": "Del",

    // #endregion
    // #region 配比方案

    "planlist_index_title": "Ratio Plan List",

    "planlist_index_status_0": "Waiting",
    "planlist_index_status_1": "Calcing",
    "planlist_index_status_2": "Finish",
    "planlist_index_status_3": "Save",
    "planlist_index_status_4": "NoRes",
    "planlist_index_btn1": "Schemes",
    "planlist_index_btn2": "DefaultSetting",
    "planlist_index_btn3": "Save",
    "planlist_index_btn4": "StartCalc",
    "planlist_index_btn5": "NewCalc",
    "planlist_index_btn6": "ToNest",

    "planlist_index_table_styleFabricInfo": "StyleNo-Fabric",
    "planlist_index_table_colorName": "Color",
    "planlist_index_table_createTime": "CalcTime",
    "planlist_index_table_ratioCount": "RatioNum",
    "planlist_index_table_bedCount": "CutBed",
    "planlist_index_table_cutCount": "CutNum",
    "planlist_index_table_layerCount": "NestNum",
    "planlist_index_table_status": "Status",

    "planlist_index_dialog_title1": "Schemes",
    "planlist_index_dialog_title2": "OrderCode",
    "planlist_index_dialog_title3": "CustomerName",

    "planlist_index_dialog_title4": "RatioCalc",

    // #endregion
    // #region 新增配比方案

    "planlist_add_detail_doorframe": "Doorframe",
    "planlist_add_detail_grammage": "Grammage",
    "planlist_add_detail_component": "Component",

    "planlist_add_detail_tip1": "No Data",

    "planlist_add_detail_btn1": "Generate table view",

    "planlist_add_detail_table_title1": "Color",
    "planlist_add_detail_table_title2": "Clockwise",
    "planlist_add_detail_table_title3": "Total",
    "planlist_add_detail_table_title4": "Layer",
    "planlist_add_detail_table_title5": "Number",

    "planlist_add_detail_table_tip1": "NestNum",
    "planlist_add_detail_table_tip1_more": "The maximum and minimum number of items in the layout of the layout plan are determined by the length of the table",
    "planlist_add_detail_table_tip2": "Laylayer",
    "planlist_add_detail_table_tip2_more": "The maximum number of layers for cutting a bed cannot exceed the height that can be cut",
    "planlist_add_detail_table_tip3": "DiffNum",
    "planlist_add_detail_table_tip3_more": "The number of pieces that can be cut more or less in a specification/size",
    "planlist_add_detail_table_tip4": "PackNum",
    "planlist_add_detail_table_tip5": "SameSpecNum",
    "planlist_add_detail_table_tip5_more": "Requirements for the number of pieces arranged in the same specification/size",
    "planlist_add_detail_table_tip6": "NestSpecNum",
    "planlist_add_detail_table_tip6_more": "Specification/size and quantity requirements for participating in the discharge process",
    "planlist_add_detail_table_tip7": "HackNum",
    "planlist_add_detail_table_tip7_more": "The fabric width can accommodate the number of large pieces of clothing",
    
    "planlist_add_detail_table_tip8_ph": "Please select the number of openings",
    "planlist_add_detail_table_tip8_op1": "Any",
    "planlist_add_detail_table_tip8_op2": "2pcs",
    "planlist_add_detail_table_tip8_op3": "3pcs",
    "planlist_add_detail_table_tip8_op4": "4pcs",
    "planlist_add_detail_table_tip8_op5": "5pcs",
    "planlist_add_detail_table_tip9": "Platform Len",
    "planlist_add_detail_table_tip10": "Ratio Type",
    "planlist_add_detail_table_tip10_op1": "General",
    "planlist_add_detail_table_tip10_op2": "Single Size",
    "planlist_add_detail_table_tip10_op3": "Double Size",
    "planlist_add_detail_table_tip10_op4": "PosOrNeg",
    "planlist_add_detail_table_tip10_op5": "Change Size",
    "planlist_add_detail_table_tip11": "Algorithm",
    "planlist_add_detail_table_tip11_op1": "Fast",
    "planlist_add_detail_table_tip11_op2": "Intellect",
    "planlist_add_detail_table_tip11_op3": "Super",
    "planlist_add_detail_table_tip12": "IfPackUp",
    "planlist_add_detail_table_tip12_op1": "Exist Ratio",
    "planlist_add_detail_table_tip12_op2": "Fixed Ratio",

    "planlist_add_detail_table_title": "Nest Setting",
    // #region ***************重点翻译专业术语
    "planlist_add_detail_table_tip13": "Nest method",
    "planlist_add_detail_table_tip13_ph": "Pleace select the nest method",
    "planlist_add_detail_table_tip13_op1": "Standard",
    "planlist_add_detail_table_tip13_op2": "Same direction by same size",
    "planlist_add_detail_table_tip13_op3": "Same direction by same piece",
    "planlist_add_detail_table_tip14": "Laying method",
    "planlist_add_detail_table_tip14_ph": "Pleace select the laying method",
    "planlist_add_detail_table_tip14_op1": "Flat",
    "planlist_add_detail_table_tip14_op2": "Fold",
    "planlist_add_detail_table_tip15": "X spacing",
    "planlist_add_detail_table_tip16": "Y spacing",
    "planlist_add_detail_table_tip17": "Warp shrinkage",
    "planlist_add_detail_table_tip18": "Weft shrinkage",

    "planlist_add_detail_table_tip99": "Page length",
    "planlist_add_detail_table_tip98": "Page interval",
    "planlist_add_detail_table_tip97": "Nest time",
    "planlist_add_detail_table_tip96": "min",
    "planlist_add_detail_table_tip95": "Rotate",
    "planlist_add_detail_table_tip95_ph": "Pleace select the rotate",
    "planlist_add_detail_table_tip94": "Tilt",
    "planlist_add_detail_table_tip93": "Corner overlap",
    "planlist_add_detail_table_tip92": "Margins",
    "planlist_add_detail_table_tip91": "Scale factor",
    "planlist_add_detail_table_tip90": "If symmetry and horizontal",
    // #endregion

    "planlist_add_detail_table_tip19": "Default Setting",

    "planlist_add_detail_table_tip20_1": "Please make sure",
    "planlist_add_detail_table_tip20_2": " and ",
    "planlist_add_detail_table_tip20_3": "same number of colors",
    "planlist_add_detail_table_tip20_4": "same color category",
    "planlist_add_detail_table_tip20_5": "the weight of cloth is the same",
    "planlist_add_detail_table_tip20_6": "same fabric composition",
    "planlist_add_detail_table_tip21": "Please form the correct table view",
    "planlist_add_detail_table_tip22": "Whether to enable forced proportioning",
    "planlist_add_detail_table_tip23": "Please generate the correct table view before submitting and saving data",
    "planlist_add_detail_table_tip24": "Success！",
    "planlist_add_detail_table_tip25": "Please generate the correct table view before operation",
    "planlist_add_detail_table_tip26": "Please generate the correct table view before creating a new operation",

    "planlist_add_detail_yes": "Yes",
    "planlist_add_detail_no": "No",
    
    // 高低层
    "planlist_add_detail_lh_title": "High or low level data",
    "planlist_add_detail_lh_table_1": "No.",
    "planlist_add_detail_lh_table_2": "RatioNum",
    "planlist_add_detail_lh_table_2_ph": "Please select the ratio",
    "planlist_add_detail_lh_table_3": "Add high or low layer",
    "planlist_add_detail_lh_table_4": "Delete high or low layer",
    "planlist_add_detail_lh_table_5": "No more ratio data to set high or low layer",

    // 排料方案参数
    "planlist_add_detail_nestset_op1": "Reconnect",
    "planlist_add_detail_nestset_op2": "CancelTask",
    
    "planlist_add_detail_nestset_title1": "Number",
    "planlist_add_detail_nestset_title2": "Layer",
    "planlist_add_detail_nestset_title3": "Bed",
    "planlist_add_detail_nestset_title4": "CutNum",
    "planlist_add_detail_nestset_title5": "PackNum",
    "planlist_add_detail_nestset_title6": "Scheme",
    "planlist_add_detail_nestset_title7": "Additional cut num",
    "planlist_add_detail_nestset_title8": "Spec",

    // #region ***************重点翻译专业术语
    "planlist_add_detail_nestset_drec_1": "Standard",
    "planlist_add_detail_nestset_drec_2": "Same direction by same size",
    "planlist_add_detail_nestset_drec_3": "Same direction by same piece",
    "planlist_add_detail_nestset_drec_4": "Single direction",
    "planlist_add_detail_nestset_drec_5": "Same direction by same size",
    "planlist_add_detail_nestset_drec_6": "Same direction",
    "planlist_add_detail_nestset_drec_7": "Material saving priority",
    "planlist_add_detail_nestset_drecTypeName_op1": "Two direction nest",
    "planlist_add_detail_nestset_drecTypeName_op2": "Single direction nest",
    // #endregion

    "planlist_add_detail_nestset_tip1": "Cancel Calc？",
    "planlist_add_detail_nestset_tip2": "Add Success",
    "planlist_add_detail_nestset_tip3": "There is no available scheme to add to the nest",

    "planlist_add_detail_nestset_nodata": "No Data",

    "planlist_add_detail_nestset_setlh": "Design high or low",
    "planlist_add_detail_nestset_drecTypeName_ph": "Please select the nest rule",

    // #endregion
    // #region 排料方案列表

    "schemelist_table_breadcrumb": "Scheme List",

    "schemelist_table_fabricInfo": "StyleNo-Fabric",
    "schemelist_table_colorNameArr": "Colors",
    "schemelist_table_component": "Component-Grammage",
    "schemelist_table_schemeName": "SchemeName",
    "schemelist_table_status": "Status",
    "schemelist_table_createTime": "ChooseTime",
    "schemelist_table_ratioCount": "RatioNum",
    "schemelist_table_totalBedCount": "BedNum",
    "schemelist_table_totalLayerCount": "LayerNum",
    "schemelist_table_totalCutCount": "CutNum",
    "schemelist_table_totalQty": "Total",

    "schemelist_table_btn1": "Create purchase order",
    "schemelist_table_btn2": "Recreate purchase order",
    "schemelist_table_btn3": "Re-Nest",

    "schemelist_table_tag1": "Waiting",
    "schemelist_table_tag2": "Completing",
    "schemelist_table_tag3": "Finish",

    "schemelist_table_tip1": "Please select the Po data to be generated",
    "schemelist_table_tip2": "If cover the purchase order？",
    "schemelist_table_tip3": "the",
    "schemelist_table_tip4": "data has not been completed, please generate a purchase order after all data have been completed!",
    "schemelist_table_tip5": "Scheme Info",

    // #endregion
    // #region 方案详情页

    "scheme_detail_title1": "NestNum",
    "scheme_detail_title2": "LayerNum",
    "scheme_detail_title3": "DiffNum",
    "scheme_detail_title4": "SameSpecNum",
    "scheme_detail_title5": "HackNum",
    "scheme_detail_title5_op1": "Any",
    "scheme_detail_title5_op2": "2pcs",
    "scheme_detail_title5_op3": "3pcs",
    "scheme_detail_title5_op4": "4pcs",
    "scheme_detail_title5_op5": "5pcs",
    "scheme_detail_title6": "NestSpecNum",
    "scheme_detail_title7": "PackNum",
    "scheme_detail_title8": "Platform-Len",
    "scheme_detail_title9": "Ratio Type",
    "scheme_detail_title9_op1": "General",
    "scheme_detail_title9_op2": "Single Size",
    "scheme_detail_title9_op3": "Double Size",
    "scheme_detail_title9_op4": "PosOrNeg",
    "scheme_detail_title9_op5": "Change Size",
    "scheme_detail_title10": "Algorithm",
    "scheme_detail_title10_op1": "Fast",
    "scheme_detail_title10_op2": "Intellect",
    "scheme_detail_title10_op3": "Super",
    "scheme_detail_title11": "Exist Ratio",
    "scheme_detail_title12": "Fixed Ratio",

    "scheme_detail_yes": "Yes",
    "scheme_detail_no": "No",

    "scheme_detail_table_title1": "Color",
    "scheme_detail_table_title2": "Total",

    "scheme_detail_table_title3": "Component",
    "scheme_detail_table_title4": "Grammage",
    "scheme_detail_table_title5": "Doorframe",
    "scheme_detail_table_title6": "Unit",
    "scheme_detail_table_title6_ph": "Pleace Select the unit",

    "scheme_detail_table_title7": "Ratio",
    "scheme_detail_table_title8": "Sum(pcs)",
    "scheme_detail_table_title9": "Layer",
    "scheme_detail_table_title10": "Net",
    "scheme_detail_table_title11": "Single layer",
    "scheme_detail_table_title12": "Single piece",
    "scheme_detail_table_title13": "Num",

    "scheme_detail_table_title14": "NestLen",
    "scheme_detail_table_title14_ph": "Input length",
    "scheme_detail_table_title15": "NestWidth",
    "scheme_detail_table_title15_ph": "Input width",
    "scheme_detail_table_title16": "Material used rate",
    "scheme_detail_table_title17": "Nest Pic",
    "scheme_detail_table_title18": "No File",
    "scheme_detail_table_title19": "Download rack file",
    "scheme_detail_table_title20": "No File",
    "scheme_detail_table_title21": "Download cut file",

    "scheme_detail_tip1": "Differ",
    "scheme_detail_tip2": "If cover the purchase order？",
    "scheme_detail_tip3": "This scheme has generated a purchase order, and the corresponding purchase order data will be cleared if you re arrange the material. Do you want to re arrange the material?",
    "scheme_detail_tip4": "Re nesting will lose the existing nesting data. Do you want to re nesting",

    // #endregion
    // #region 采购单列表数据

    "planbuy_index_title1": "Purchase order",
    "planbuy_index_title2": "Purchase task order",
    "planbuy_index_title3": "Purchase plan",

    "planbuy_index_btn1": "Real purchase",
    "planbuy_index_btn2": "View Tasks",
    "planbuy_index_btn3": "Generate task order",
    "planbuy_index_btn4": "CancelAudit",
    "planbuy_index_btn5": "Enter fabric composition",
    "planbuy_index_btn6": "Do not enter fabric composition",

    "planbuy_index_colours": "Color",
    "planbuy_index_fabricName": "FabricName",
    "planbuy_index_component": "Component",
    "planbuy_index_doorframe": "Doorframe",
    "planbuy_index_grammage": "Grammage",
    "planbuy_index_unit": "Unit",
    "planbuy_index_createTime": "Generation Time",
    "planbuy_index_createUser": "Create User",

    // #endregion
    // #region 采购单详情

    "planbuy_detail_title1": "FabricName",
    "planbuy_detail_title2": "Component",
    "planbuy_detail_title3": "Grammage",
    "planbuy_detail_title3_ph": "Grammage",
    "planbuy_detail_title4": "LayingSurplus",
    "planbuy_detail_title4_ph": "Input the surplus",
    "planbuy_detail_title5": "CutBand-Len/Wid",
    "planbuy_detail_title5_ph1": "Length",
    "planbuy_detail_title5_ph2": "Width",
    "planbuy_detail_title5_ph3": "pcs",
    "planbuy_detail_title6": "Head and tail loss",
    "planbuy_detail_title7": "Selvage width",
    "planbuy_detail_title8": "Unit",
    "planbuy_detail_title8_ph": "Pleace select the unit",

    "planbuy_detail_table_title1": "Single layer",
    "planbuy_detail_table_title2": "Size",
    "planbuy_detail_table_title3": "Sum",
    "planbuy_detail_table_title4": "Nest-Pic",
    "planbuy_detail_table_title5": "Nest-Len",
    "planbuy_detail_table_title6": "Nest-Wid",
    "planbuy_detail_table_title7": "Material used rate",
    "planbuy_detail_table_title8": "Layer Num",
    "planbuy_detail_table_title9": "Single layer",
    "planbuy_detail_table_title10": "Net",
    "planbuy_detail_table_title11": "CutBand",
    "planbuy_detail_table_title12": "Gross",
    "planbuy_detail_table_title13": "Single piece",

    "planbuy_detail_table_title14": "Color/Ratio",
    "planbuy_detail_table_title15": "Weaving loss",
    "planbuy_detail_table_title16": "View ratio info",
    "planbuy_detail_table_title17": "Dyeing loss",
    "planbuy_detail_table_title18": "Cut loss",
    "planbuy_detail_table_title18_1": "Num",

    "planbuy_detail_table_title19": "Material for single",
    "planbuy_detail_table_title20": "UnitPrice ",
    "planbuy_detail_table_title20_ph": "Input the unit price",
    "planbuy_detail_table_title21": "yuan",
    "planbuy_detail_table_title22": "UnitCost ",
    "planbuy_detail_table_title23": "TotalPrice ",
    "planbuy_detail_table_title24": "Tatol colors",

    "planbuy_detail_tip1": "Save success",
    "planbuy_detail_tip2": "AuditSuccess",
    "planbuy_detail_tip3": "The purchase task order will be cleared after de approval. Do you want to continue?",
    "planbuy_detail_tip4": "CancelAudit",

    // #endregion
    // #region 采购任务单

    "planbuy_mission_title1": "Fabric Name",
    "planbuy_mission_title2": "Component",
    "planbuy_mission_title3": "Laying Surplus",
    "planbuy_mission_title4": "Total",
    "planbuy_mission_title5": "CutBand-Len/Wid",
    "planbuy_mission_title6": "Head and tail loss",
    "planbuy_mission_title7": "Selvage width",
    "planbuy_mission_title8": "Unit",
    "planbuy_mission_title9": "Weaving loss",
    "planbuy_mission_title10": "Dyeing loss",
    "planbuy_mission_title11": "Cut loss",

    "planbuy_mission_title12": "Ratio Name",
    "planbuy_mission_title13": "Fabric Num",
    "planbuy_mission_title14": "Fabric Detail",
    "planbuy_mission_title15": "Doorframe",
    "planbuy_mission_title16": "Grammage",
    "planbuy_mission_title17": "Vat Num",
    "planbuy_mission_title18": "Single fabric fluctuation",
    "planbuy_mission_title19": "Single ratio material",
    "planbuy_mission_title20": "Component name",
    "planbuy_mission_title20_ph": "Pleace input the component name",
    "planbuy_mission_title21": "Percent",
    "planbuy_mission_title21_ph": "Pleace input the percent",
    "planbuy_mission_title22": "Purchase quantity",
    "planbuy_mission_title23": "New fabric composition",
    "planbuy_mission_title24": "Delete fabric composition",

    // #endregion
    // #region 实际采购数据

    "planbuy_real_title1": "Color",
    "planbuy_real_title2": "Original planned purchase",
    "planbuy_real_title3": "Real planned purchase ",
    "planbuy_real_title3_ph": "Pleace input the real planned purchase",
    "planbuy_real_title4": "Plan production num ",
    "planbuy_real_title5": "Expect real production num ",
    "planbuy_real_title6": "Expect product success rate",
    "planbuy_real_title7": "pcs",

    "planbuy_real_tip1": "Success",

    // #endregion
    // #region 辅料单数据

    "support_index_title1": "Auxiliary material order",
    "support_index_title2": "StyleNo",
    "support_index_title3": "Style",
    "support_index_title4": "PO",
    "support_index_title5": "Delivery Date",
    
    "support_index_title6": "Name",
    "support_index_title7": "Unit",
    "support_index_title8": "Size",
    "support_index_title9": "Num",
    "support_index_title10": "Usage/pcs",
    "support_index_title11": "Total",
    "support_index_title12": "Used Part",
    "support_index_title13": "Quality",
    "support_index_title14": "Pic",

    "support_index_btn1": "Edit",

    // #endregion
    // #region 新增辅料单数据

    "support_detail_title1": "Choose Setting",
    "support_detail_title1_ph": "Pleace select the setting",
    "support_detail_title2": "Custom",
    "support_detail_title3": "Customer",
    "support_detail_title3_ph": "Pleace input the customer",
    "support_detail_title4": "Product Name",
    "support_detail_title4_ph": "Pleace input the product name",
    "support_detail_title5": "StyleNo",
    "support_detail_title5_ph": "Pleace input the styleno",
    "support_detail_title6": "PO",
    "support_detail_title6_ph": "Pleace input the PO",
    "support_detail_title7": "Order Code",
    "support_detail_title7_ph": "Pleace input the order code",
    "support_detail_title8": "Num",
    "support_detail_title8_ph": "Pleace input the num",

    "support_detail_title9": "Name",
    "support_detail_title9_ph": "Pleace input the name",
    "support_detail_title10": "Unit",
    "support_detail_title10_ph": "Pleace input the unit",
    "support_detail_title11": "Size",
    "support_detail_title11_ph": "Pleace input the size",
    "support_detail_title12": "Num",
    "support_detail_title12_ph": "Pleace input the num",
    "support_detail_title13": "Usage/pcs",
    "support_detail_title13_ph": "Pleace input the usage(pcs)",
    "support_detail_title14": "Total",
    "support_detail_title15": "Used Part",
    "support_detail_title15_ph": "Pleace input the used part",
    "support_detail_title16": "Quality",
    "support_detail_title16_ph": "Pleace input the quality",
    "support_detail_title17": "Pic",
    "support_detail_title18": "Setting template",
    "support_detail_title19": "If delete this template",
    "support_detail_title20": "If delete this data",
    "support_detail_title21": "If reupload the color card？",

    "support_detail_btn1": "Edit",
    "support_detail_btn2": "Del",
    "support_detail_btn3": "Add custom setting",
    "support_detail_btn4": "Upload Pic",

    "support_detail_tip1": "Success",

    // #endregion
    // #region 辅料单模板页

    "support_template_title1": "Custom Name",
    "support_template_title1_ph": "Pleace input the custom name",
    "support_template_title2": "Name",
    "support_template_title2_ph": "Pleace input the name",
    "support_template_title3": "Unit",
    "support_template_title3_ph": "Pleace input the unit",

    "support_template_tip1": "Success",

    // #endregion
    // #region 用户信息列表

    "user_index_title": "User List",

    "user_index_title1": "male",
    "user_index_title2": "female",
    "user_index_title3": "other",

    "user_index_tag1": "Normal",
    "user_index_tag2": "Freeze",

    "user_index_table_userName": "Account",
    "user_index_table_nickName": "NickName",
    "user_index_table_createTime": "CreateTime",
    "user_index_table_sex": "Sex",
    "user_index_table_phone": "Phone",
    "user_index_table_email": "Email",
    "user_index_table_introduction": "Introduction",
    "user_index_table_state": "State",

    "user_index_tip1": "User Info",

    // #endregion
    // #region 用户信息详情

    "user_detail_title1": "Account",
    "user_detail_title1_ph": "Pleace input the account",
    "user_detail_title2": "Password",
    "user_detail_title2_ph": "Pleace input the password",
    "user_detail_title3": "NickName",
    "user_detail_title3_ph": "Pleace input the nickname",
    "user_detail_title4": "Sex",
    "user_detail_title4_ph": "Pleace select the sex",
    "user_detail_title5": "Phone",
    "user_detail_title5_ph": "Pleace input the phone",
    "user_detail_title6": "Email",
    "user_detail_title6_ph": "Pleace input the email",
    "user_detail_title7": "Status",
    "user_detail_title7_ph": "Pleace select the status",
    "user_detail_title8": "Role",
    "user_detail_title8_ph": "Pleace select the role",
    "user_detail_title9": "Bind Wechat",
    "user_detail_title9_btn1": "Unbind Wechat",
    "user_detail_title9_tag1": "Binded",
    "user_detail_title9_tag2": "Unbind",
    "user_detail_title10": "Introduction",
    "user_detail_title10_ph": "Pleace input the introduction",
    
    "user_detail_title11": "Phone",
    "user_detail_title12": "SMSCode",
    "user_detail_title12_ph": "Pleace input the SMScode",
    "user_detail_title13": "SMS-Verify",

    "user_detail_sex_op1": "male",
    "user_detail_sex_op2": "female",
    "user_detail_state_op1": "Normal",
    "user_detail_state_op2": "Freeze",

    "user_detail_btn1": "Send",

    "user_detail_tip1": "Pleace bind phone number first",
    "user_detail_tip2": "Pleace input the right phone number",
    "user_detail_tip3": "Unbind Success",
    "user_detail_tip4": "Save Success",

    // #endregion
    // #region 角色

    "role_list_title1": "Role List",
    "role_list_title2": "Role Info",

    "role_list_title3": "Role Name",
    "role_list_title3_ph": "Pleace input the role name",
    "role_list_title4": "Menu List",

    "role_list_table_roleName": "Account",
    "role_list_table_isAdmin": "If System Role",

    "role_list_yes": "Yes",
    "role_list_no": "No",

    "role_list_btn1": "BindMenu",
    "role_list_btn2": "Bind",

    "role_list_tip1": "Success",

    // #endregion
    // #region 系统设置

    "sys_index_title1": "System Setting",

    "sys_index_ph1": "Pleace input the value",
    "sys_index_ph2": "Pleace select the value",
    "sys_index_ph3": "Pleace select the date",

    // #endregion
    // #region 企业数据

    "store_detail_enterpriseName": "Enterprise Name",
    "store_detail_enterpriseName_ph": "Pleace input the enterprise name",
    "store_detail_passWord": "Password",
    "store_detail_passWord_ph": "Pleace input the password",
    "store_detail_rePassWord": "Check password",
    "store_detail_rePassWord_ph": "Pleace check the password",
    "store_detail_enterpriseMobile": "Register Phone",
    "store_detail_enterpriseMobile_ph": "Pleace input the enterprise phone",
    "store_detail_smsCode": "Code",
    "store_detail_smsCode_ph": "Pleace input the code",

    "store_detail_tip1": "I have read and agree",
    "store_detail_tip2": "Please log in with your mobile number as your account after registration",
    "store_detail_tip3": "Send",
    "store_detail_tip4": "Please fill in the enterprise registered mobile phone to obtain the verification code",
    "store_detail_tip5": "Please fill in the information in the form",
    "store_detail_tip6": "The two passwords do not match, please confirm the password again",
    "store_detail_tip7": "Password length must be between 6 and 8 bits",
    "store_detail_tip8": "Passwords must consist of numbers and characters",
    "store_detail_tip9": "Please read and agree to the terms of service before registering for use",

    // #endregion

  }
  // #endregion
}

export default {
  language
}